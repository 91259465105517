<template>
  <div class="filterColumns">
    <Button type="primary" @click.stop="toggleFilterColumns">
      {{ $t('key1000098') }}
      <!--<Icon type="ios-settings" style="margin-right: 5px;"></Icon>-->
      <Icon :type="filterColumnsIcon" size="small"></Icon>
    </Button>
    <transition name="slide-fade">
      <Card
        dis-hover
        class="filterColumnsBox"
        v-show="filterColumnsIcon!='ios-arrow-up'"
        @click.native.stop="hideCardStop"
        ref="filterCard">
        <Row>
          <Col span="24">
            <p style="border-bottom: 1px solid #ddd;">{{ $t('key1000099') }}
              <span style="color: #2d8cf0;cursor: pointer;" @click="filterColumsReset">{{ $t('key1000100') }}</span>
              <span style="color: #2d8cf0;cursor: pointer;float: right" @click="saveTableCache">{{ $t('key1000101') }}</span>
            </p>
          </Col>
          <Col span="12" v-for="(item,index) in columnsInit" :key="index">
            <Checkbox
              v-model="item.check" :disabled="!!item.requiredCheck" @on-change="filterColumsChange">{{ item.title }}
            </Checkbox>
          </Col>

        </Row>
      </Card>
    </transition>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/index';
// filterHide 是否隐藏
export default {
  name: 'filterColumns',
  props: ['columns', 'filterName'], // table 列  本地缓存名字
  mixins: [Mixin],
  components: {},
  data() {
    return {
      locolColumus: [],
      columnsInit: [],
      filterColumnsIcon: 'ios-arrow-up'
    };
  },
  mounted() {
    let v = this;
    v.init();
  },
  watch: {
    filterName(n, o) {
      if (n && n !== o) {
        this.init();
      }
    }
  },
  methods: {
    /**
     *
     * @date: 2022/5/12 15:30
     * @param types array
     */
    getColumns (types) {
      let v = this;
      return new Promise(resolve => {
        v.axios.post(api.post_erpCommonSettingParam_queryByParamKeys, types).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            resolve(data);
          }
        });
      });
    },
    saveHandel (dataJson) {
      let v = this;
      v.axios.post(api.post_erpCommonSettingParam_save, {
        paramBoList: [
          {
            paramKey: 'packingGoodsCustom',
            paramValue: dataJson
          }
        ]
      }).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000102'));
        }
      });
    },
    init () {
      let v = this;
      v.columnsInit = v.columns;
      v.columnsInit.forEach(item => {
        item.check = false;
      });
      v.locolColumus = [];
      v.getColumns(['packingGoodsCustom']).then((data) => {
          if (data && data.length) {
            let arr = data.filter(i => i.paramKey === 'packingGoodsCustom')[0].paramValue;
            if (arr) {
              JSON.parse(arr).forEach(item => {
                v.columnsInit.forEach(column => {
                  if (item.key === column.key) {
                    column.check = true;
                    v.locolColumus.push(column);
                  }
                });
              });
            } else {
              let arr = [];
              v.columns.forEach(item => {
                if (!item.filterHide || item.requiredCheck) {
                  arr.push(item);
                }
              });
              v.locolColumus = arr;
              v.locolColumus.forEach(item => {
                item.check = true;
              });
            }
          } else {
            let arr = [];
            v.columns.forEach(item => {
              if (!item.filterHide || item.requiredCheck) {
                arr.push(item);
              }
            });
            v.locolColumus = arr;
            v.locolColumus.forEach(item => {
              item.check = true;
            });
          }
          v.$emit('setTableColumns', v.locolColumus);
          document.addEventListener('click', v.hideCard);
      })

    },
    toggleFilterColumns() {
      let v = this;
      v.filterColumnsIcon === 'ios-arrow-down'
        ? v.filterColumnsIcon = 'ios-arrow-up'
        : v.filterColumnsIcon = 'ios-arrow-down';
    },
    hideCardStop() {
    },
    hideCard() {
      let v = this;
      v.filterColumnsIcon = 'ios-arrow-up';
    },
    filterColumsReset() {
      let v = this;
      v.columnsInit = [];
      setTimeout(() => {
        let arr = [];
        v.columnsInit = v.columns;
        v.columnsInit.forEach(item => {
          if (!item.filterHide) {
            item.check = true;
          } else {
            item.check = false;
          }
        });
        v.columns.forEach(item => {
          if (!item.filterHide) {
            arr.push(item);
          }
        });
        // localStorage.setItem(v.filterName, JSON.stringify(arr));
        v.saveHandel(null);
        v.$emit('setTableColumns', arr);
      }, 10);
    },
    filterColumsChange() {
      let arr = [];
      let v = this;
      v.columnsInit.forEach(item => {
        if (item.check) {
          arr.push(item);
        }
      });
      v.$emit('setTableColumns', arr);
    },
    saveTableCache () {
      let arr = [];
      let v = this;
      v.columnsInit.forEach(item => {
        if (item.check) {
          arr.push(item);
        }
      });
      v.saveHandel(JSON.stringify(arr));
    }
  },
  destroyed() {
    document.removeEventListener('click', this.hideCard);
  }
};
</script>

<style scoped>
.filterColumns {

}

.filterColumnsBox {
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 10;
  width: 300px;
  background-color: #ffffff;
}
</style>
